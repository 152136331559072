<template>
  <div>
    <modal
      ref="modal"
      :showing="showing"
      :show-close="showClose"
      :background-close="backgroundClose"
      :small="true"
      @close="close"
    >
      <div class="bg-grayVeryLight relative mx-auto lg:mx-0 p-24 lg:p-64 text-black">
        <div class="grid grid-cols-12 gap-24 text-center">
          <h1 class="h1 mb-32 col-span-12">
            Sprache/Language
          </h1>
          <div class="col-start-1 col-span-12 mb-16 text-gray text-left">
            <p class="mb-8">
              Die aktuelle Sprache der Webseite ist "{{ getLanguageName($root.$i18n.locale) }}". Möchten Sie in dieser Sprachversion bleiben oder zu einer anderen wechseln?
            </p>
            <p class="mb-8">
              The current language of the website is "{{ getLanguageName($root.$i18n.locale) }}". Would you like to remain on this language version or switch to another one?
            </p>
            <!--
            <p class="mb-8">
              La langue actuelle du site web est "{{ getLanguageName($root.$i18n.locale) }}". Souhaitez-vous rester dans cette version linguistique ou en changer?
            </p>
            <p class="mb-8">
              La lingua attuale del sito web è "{{ getLanguageName($root.$i18n.locale) }}". Vorresti rimanere in questa versione linguistica o passare a un'altra?
            </p>
            <p class="mb-8">
              El idioma actual del sitio web es "{{ getLanguageName($root.$i18n.locale) }}". ¿Desea permanecer en esta versión de idioma o cambiar a otra?
            </p>
            <p class="mb-8">
              A linguagem atual do site é "{{ getLanguageName($root.$i18n.locale) }}". Gostaria de permanecer nesta versão do idioma ou mudar para outra?
            </p>
            <p class="mb-8">
              De huidige taal van de website is "{{ getLanguageName($root.$i18n.locale) }}". Wilt u op deze taalversie blijven of naar een andere overschakelen?
            </p>
            <p class="mb-8">
              Aktualny język strony to "{{ getLanguageName($root.$i18n.locale) }}". Czy chcesz pozostać w tej wersji językowej, czy przełączyć na inną?
            </p>
            <p class="mb-8">
              Текущий язык сайта - "{{ getLanguageName($root.$i18n.locale) }}". Хотите остаться на этой языковой версии или переключиться на другую?
            </p>
            <p class="mb-8">
              اللغة الحالية للموقع هي "{{ getLanguageName($root.$i18n.locale) }}". هل ترغب في البقاء في هذا الإصدار اللغوي أم تغيير إلى آخر؟
            </p>
            <p class="mb-8">
              השפה הנוכחית של האתר היא "{{ getLanguageName($root.$i18n.locale) }}". האם אתה רוצה להישאר בגרסה זו של השפה או לעבור לאחרת?
            </p>
            -->
          </div>
          <div class="col-start-1 col-span-12 mb-16">
            <select v-model="$root.$i18n.locale" type="select" class="language-switcher language-switcher--large col-span-1">
              <option v-if="isEmpty(languages) || languages.includes('de')" value="de">
                Deutsch
              </option>
              <option v-if="isEmpty(languages) || languages.includes('en')" value="en">
                English
              </option>
              <option v-if="isEmpty(languages) || languages.includes('es')" value="es">
                Español
              </option>
              <option v-if="isEmpty(languages) || languages.includes('fr')" value="fr">
                Français
              </option>
              <option v-if="isEmpty(languages) || languages.includes('it')" value="it">
                Italiano
              </option>
              <option v-if="isEmpty(languages) || languages.includes('nl')" value="nl">
                Nederlands
              </option>
              <option v-if="isEmpty(languages) || languages.includes('pl')" value="pl">
                Polski
              </option>
              <option v-if="isEmpty(languages) || languages.includes('pt')" value="pt">
                Português
              </option>
              <option v-if="isEmpty(languages) || languages.includes('ru')" value="ru">
                Русский язык
              </option>
              <option v-if="isEmpty(languages) || languages.includes('el')" value="el">
                Ελληνικά
              </option>
            </select>
          </div>
          <button-big class="col-start-1 col-span-12" @click="/* */">
            <span v-if="$root.$i18n.locale == 'de'" class="text-16 text-white font-medium" @click="confirmAndClose">
              Sprachauswahl bestätigen
            </span>
            <span v-else-if="$root.$i18n.locale == 'en'" class="text-16 text-white font-medium" @click="confirmAndClose">
              Confirm language selection
            </span>
            <span v-else class="text-16 text-white font-medium" @click="confirmAndClose">
              Confirm language selection
            </span>
          </button-big>
          <div class="text-gray mt-16 col-start-1 col-span-12 text-body-small hidden lg:block">
            <p class="mb-8">
              Die Auswahl der Sprache kann jederzeit, auch später, in der linken unteren Ecke geändert werden
            </p>
            <p class="mb-8">
              The language selection can be changed at any time, even later, in the bottom left corner.
            </p>
          </div>
          <div class="text-gray mt-16 col-start-1 col-span-12 text-body-small lg:hidden">
            <p class="mb-8">
              Die Auswahl der Sprache kann jederzeit, auch später, im Menü (zum Öffnen in die recht obere Ecke klicken) geändert werden.
            </p>
            <p class="mb-8">
              The language selection can be changed at any time, even later, in the menu (click in the top right corner to open).
            </p>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { XIcon } from 'vue-feather-icons';
import Modal from './Modal';
import ButtonBig from '../ButtonBig/ButtonBig';
import { isEmpty, isNil } from 'lodash/lang';

export default {
  name: 'LanguageModal',

  components: {
    XIcon,
    Modal,
    ButtonBig,
  },

  props: {
    // showing: {
    //   required: true,
    //   type: Boolean,
    // },
    showClose: {
      type: Boolean,
      default: true,
    },
    backgroundClose: {
      type: Boolean,
      default: true,
    },
    languages: {
      type: Array,
      default: () => ['de', 'en'],
      required: false,
    },
  },

  data: function () {
    return {
      showing: false,
    };
  },

  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body')
          .classList
          .add('overflow-hidden');
      }
      return document.querySelector('body')
        .classList
        .remove('overflow-hidden');
    },
  },

  created() {
    this.$serviceBus.$on('shortcut.esc', this.close);
    this.$serviceBus.$on('languageModal.show', () => { this.showing = true; });
    this.$serviceBus.$on('languageModal.hide', () => {
      this.showing = false;
      // this.close();
    });
  },

  beforeDestroy() {
    this.$serviceBus.$off('shortcut.esc', this.close);
    this.$serviceBus.$off('languageModal.show');
    this.$serviceBus.$off('languageModal.hide');
  },

  methods: {
    isEmpty,
    close() {
      this.$serviceBus.$emit('languageModal.hide');
      // this.$refs.modal.close();
    },
    getLanguageName(lang) {
      switch (lang) {
        case 'de': return 'Deutsch';
        case 'en': return 'English';
        case 'es': return 'Español';
        case 'fr': return 'Français';
        case 'it': return 'Italiano';
        case 'nl': return 'Nederlands';
        case 'pl': return 'Polski';
        case 'pt': return 'Português';
        case 'ru': return 'Русский язык';
        case 'el': return 'Ελληνικά';
        default: return lang;
      }
    },
    confirmAndClose() {
      this.$serviceBus.$emit('languageModal.confirm', this.$root.$i18n.locale);
      this.close();
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.6s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
